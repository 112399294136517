<template>
  <div class="bg-black">
    <div class="flex flex-col justify-center items-center h-screen text-center text-white">
      <h1>404 - Not Found</h1>
      <p class="mb-4">The page you're looking for does not exist.</p>
      <img class="inline w-1/2 md:w-1/4 lg:w-1/6 xl:w-1/8" src="../assets/assets24/blue_collar_goob.png" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFoundComponent'
}
</script>
